import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { navigate } from 'gatsby';
import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';
import * as yup from 'yup';
import { AnimatePresence, motion } from 'framer-motion';
import { heightCollapse } from 'utils/motion/height-collapse';

import AuthLayout from 'layouts/auth-layout/auth-layout';
import { useLocationQuery } from 'utils/hooks/use-query';
import { AUTH_LOGIN_PAGE } from 'site-settings/site-navigation';
import { resetPassAPI, resetPassConfirmAPI } from 'utils/api';
import { passwordRe } from 'utils/constant';
import { useNotify } from 'contexts/notification.context';
import { Input } from 'components/common/form/input';
import { PasswordInput } from 'components/password-input/password-input';
import { Button } from 'components/common/button/button';
import LeftArrowIcon from 'components/icons/LeftArrowIcon';

import {
  FormField,
  FormFieldsWrapper,
  FormRow,
  Heading,
  InfoText,
  LabelError,
  LabelInput,
  LinkButton,
  Offer,
  OptionalText,
  Wrapper
} from 'assets/styles/form.style';
import SEO from '../../components/seo';

export default function ForgotPasswordFormPage() {
  const { t } = useTranslation();
  const token = useLocationQuery('token');
  const [step, setStep] = useState('form');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const { setMessage, setMessageError } = useNotify();

  useEffect(() => {
    if (token !== null) {
      setStep('afterForm');
    }
  }, []);

  const resetCallback = async (
    values,
    { touched, validateForm, setTouched, setSubmitting, resetForm }
  ) => {
    const errs = await validateForm();

    if (isEmpty(errs)) {
      try {
        setLoading(true);
        await resetPassAPI(values);
        setLoading(false);
        setEmail(values.email);
        setSubmitting(false);
        setStep('instructions');
      } catch (err) {
        resetForm();
        setMessageError(err);
        setLoading(false);
      }
    } else {
      setTouched({ ...touched, ...errs });
    }
  };

  const submitCallback = async (
    values,
    { touched, validateForm, setTouched, setSubmitting, resetForm }
  ) => {
    const errs = await validateForm();
    if (isEmpty(errs)) {
      try {
        setLoading(true);
        await resetPassConfirmAPI({ ...values, token });
        setLoading(false);
        setSubmitting(false);
        setMessage('notifications.auth.reset', {
          status: 'success'
        });
        navigate(AUTH_LOGIN_PAGE);
      } catch (err) {
        resetForm();
        setLoading(false);
      }
    } else {
      setTouched({ ...touched, ...errs });
    }
  };

  return (
    <AuthLayout>
      <Wrapper>
        <Heading>{t('authForm.resetPassText')}</Heading>
        {step === 'form' && (
          <>
            <Formik
              validateOnBlur
              initialValues={{
                email: ''
              }}
              validationSchema={yup.object().shape({
                email: yup
                  .string()
                  .required(t('authForm.loginFields.emailRequired'))
                  .email(t('authForm.loginFields.emailValid'))
                  .max(50, t('authForm.loginFields.emailMax'))
              })}
              onSubmit={resetCallback}
            >
              {({
                isValid,
                values,
                handleChange,
                handleBlur,
                touched,
                errors
              }) => (
                <Form noValidate>
                  <FormFieldsWrapper>
                    <FormRow>
                      <FormField>
                        <LabelInput htmlFor="email">
                          {t('authForm.emailLabel')}
                        </LabelInput>
                        <Input
                          type="email"
                          name="email"
                          placeholder={t('authForm.emailPlaceholder')}
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(errors.email) && touched.email}
                          required
                        />
                        <AnimatePresence>
                          {Boolean(errors.email) && touched.email && (
                            <motion.div
                              initial="from"
                              animate="to"
                              exit="from"
                              variants={heightCollapse()}
                            >
                              <LabelError>{errors.email}</LabelError>
                            </motion.div>
                          )}
                        </AnimatePresence>
                      </FormField>
                    </FormRow>
                    <FormRow>
                      <Button
                        loading={loading}
                        disabled={!isValid}
                        type="submit"
                        fullwidth
                      >
                        {t('authForm.resetPasswordBtn')}
                      </Button>
                    </FormRow>
                  </FormFieldsWrapper>
                </Form>
              )}
            </Formik>
            <Offer align="center">
              {t('authForm.backToText')}{' '}
              <LinkButton to={AUTH_LOGIN_PAGE}>
                {t('authForm.loginBtnText')}
              </LinkButton>
            </Offer>
          </>
        )}
        {step === 'afterForm' && (
          <>
            <Formik
              validateOnBlur
              initialValues={{
                password: '',
                confirmPassword: ''
              }}
              validationSchema={yup.object().shape({
                password: yup
                  .string()
                  .required(t('authForm.registerFields.passwordRequired'))
                  .min(8, t('authForm.registerFields.passwordMin'))
                  .max(32, t('authForm.registerFields.passwordMax'))
                  .matches(
                    passwordRe,
                    t('authForm.registerFields.passwordMatches')
                  ),
                confirmPassword: yup.string().when('password', {
                  is: (value) => value && value.length > 0,
                  then: yup
                    .string()
                    .required(t('authForm.registerFields.passwordRequired'))
                    .oneOf(
                      [yup.ref('password'), null],
                      t('authForm.registerFields.passwordMatch')
                    )
                })
              })}
              onSubmit={token !== null ? submitCallback : undefined}
            >
              {({
                isValid,
                values,
                handleChange,
                handleBlur,
                touched,
                errors
              }) => (
                <Form noValidate>
                  <FormFieldsWrapper>
                    <FormRow>
                      <FormField>
                        <LabelInput htmlFor="password">
                          {t('authForm.passwordLabel')}
                        </LabelInput>
                        <PasswordInput
                          id="password"
                          name="password"
                          placeholder={t('authForm.passwordPlaceholder')}
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(errors.password) && touched.password}
                          required
                        />
                        <AnimatePresence>
                          {Boolean(errors.password) && touched.password && (
                            <motion.div
                              initial="from"
                              animate="to"
                              exit="from"
                              variants={heightCollapse()}
                            >
                              <LabelError>{errors.password}</LabelError>
                            </motion.div>
                          )}
                        </AnimatePresence>
                      </FormField>
                    </FormRow>
                    <FormRow>
                      <FormField>
                        <LabelInput htmlFor="confirmPassword">
                          {t('authForm.confirmPassLabel')}
                        </LabelInput>
                        <PasswordInput
                          id="confirmPassword"
                          name="confirmPassword"
                          placeholder={t('authForm.confirmPasswordPlaceholder')}
                          value={values.confirmPassword}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            Boolean(errors.confirmPassword) &&
                            touched.confirmPassword
                          }
                          required
                        />
                        <AnimatePresence>
                          {Boolean(errors.confirmPassword) &&
                            touched.confirmPassword && (
                              <motion.div
                                initial="from"
                                animate="to"
                                exit="from"
                                variants={heightCollapse()}
                              >
                                <LabelError>
                                  {errors.confirmPassword}
                                </LabelError>
                              </motion.div>
                            )}
                        </AnimatePresence>
                      </FormField>
                    </FormRow>
                    <FormRow>
                      <Button
                        loading={loading}
                        disabled={!isValid}
                        type="submit"
                        fullwidth
                      >
                        {t('authForm.resetPasswordBtn')}
                      </Button>
                    </FormRow>
                  </FormFieldsWrapper>
                </Form>
              )}
            </Formik>
            <Offer align="center">
              {t('authForm.backToText')}{' '}
              <LinkButton to={AUTH_LOGIN_PAGE}>
                {t('authForm.loginBtnText')}
              </LinkButton>
            </Offer>
          </>
        )}
        {step === 'instructions' && (
          <>
            <FormRow>
              <OptionalText>{t('authForm.instructions1')}</OptionalText>
            </FormRow>
            <FormRow>
              <Input
                type="email"
                name="email"
                readonly
                placeholder={t('authForm.emailPlaceholder')}
                value={email}
              />
            </FormRow>
            <FormRow className="ext">
              <OptionalText>{t('authForm.instructions2')}</OptionalText>
            </FormRow>
            <FormRow>
              <InfoText>{t('authForm.instructions3')}</InfoText>
            </FormRow>
            <LinkButton fontSize="sm" to="/">
              <LeftArrowIcon />
              {t('authForm.backToText')}
            </LinkButton>
          </>
        )}
      </Wrapper>
    </AuthLayout>
  );
}

export const Head = () => {
  const { t } = useTranslation();
  return <SEO title={t('authForm.resetPassText')} />;
};
