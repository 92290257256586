const LeftArrowIcon = ({ color = 'currentColor' }) => {
  return (
    <svg
      width="7"
      height="12"
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.17208 9.46254L2.36631 5.58252L6.17208 1.70251C6.55462 1.31251 6.55462 0.682503 6.17208 0.292501C5.78954 -0.0975004 5.17159 -0.0975004 4.78905 0.292501L0.286866 4.88252C-0.0956726 5.27252 -0.0956726 5.90253 0.286866 6.29253L4.78905 10.8825C5.17159 11.2725 5.78954 11.2725 6.17208 10.8825C6.54481 10.4925 6.55462 9.85254 6.17208 9.46254Z"
        fill={color}
      />
    </svg>
  );
};

export default LeftArrowIcon;
